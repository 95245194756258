export const defaultFaceRecognitionSettings: any = {
  active: false,
  customAttribute: null,
  labelType: "tag",
};

export const defaultYearMetadataSettings: any = {
  active: false,
  customAttribute: null,
  labelType: "tag",
};
