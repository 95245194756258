import { useEffect, useState } from "react";

import { GlobalContext } from "./index";

// This context to store shared value across all pages/components
export default ({ children }: any) => {
  const [storedSortFilter, setStoredSortFilter] = useState<any>();
  const [globalSearch, setGlobalSearch] = useState<any>();
  const [folderSort, setFolderSort] = useState("");
  const [folderField, setFolderField] = useState("");
  const [routeChanged, setRouteChanged] = useState(false);
  const [storedActiveSubFolders, setStoredActiveSubFolders] = useState<any>();
  const [storedHeaderName, setStoredHeaderName] = useState<any>("");
  const [isMobile, setIsMobile] = useState(false);
  const globalValue = {
    storedSortFilter,
    setStoredSortFilter,
    globalSearch,
    folderField,
    setFolderSort,
    setFolderField,
    folderSort,
    routeChanged,
    setRouteChanged,
    setGlobalSearch,
    storedActiveSubFolders,
    setStoredActiveSubFolders,
    setStoredHeaderName,
    storedHeaderName,
    isMobile,
  };

  const handleResize = () => {
    // @ts-ignore
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("resize", handleResize);
    return () => {
      // @ts-ignore
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <GlobalContext.Provider value={globalValue}>{children}</GlobalContext.Provider>;
};
